<style scoped>
.text-striked {
  /* color:blueviolet */
  text-decoration-line: line-through;
}
.table {
  color: unset;
}
.comment {
  display: block;
  margin-bottom: 0em;
  white-space: pre-wrap;
  font-family: monospace;
}
.comment-date {
  display: block;
}
.comment-amount{
  font-weight: bold;
}
.uuid {
  color:#4afa769d;
  background-color: #283748;
}
.money {
  color: #faa64a;
  font-weight: bold;
}
.vuetable-slot {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
<template>
  <div class="">
    <Vuetable
      ref="vuetable"
      :api-url="apiurl"
      :fields="fields"
      :row-class="onReconLogRowClass"
      :http-options="{withCredentials: true}"
      track-by="_id"
      data-path="rows"
      class="comment-table"
      pagination-path=""
      @vuetable:pagination-data="onPaginationData"
      @recon-attach-contact="tryAutoAttachContact"
      @recon-attach-cfdiinfo="tryAutoUpdateRecon"
    >
      <template slot="actions" slot-scope="props">
        <div class="table-button-container">
          <p style="display: inline-flex;">
            <!-- <Icon v-show="reconHasCfdiMetadata(props.rowData)" icon="material-symbols:smart-toy-outline-sharp" /> -->
            <!-- <Icon v-show="reconHasCfdiMetadata(props.rowData)" icon="material-symbols:smart-toy-outline-sharp" /> -->
            <!-- <Icon icon="ph:x-thin" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" /> -->
            <a v-if="(typeof props.rowData.meta!='undefined' && props.rowData.meta.status!='shown')" href="#" class="" @click="markLog('shown', {...props.rowData})">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M13.5 7a6.5 6.5 0 0 1 6.5 6.5a6.5 6.5 0 0 1-6.5 6.5H10v-2h3.5c2.5 0 4.5-2 4.5-4.5S16 9 13.5 9H7.83l3.08 3.09L9.5 13.5L4 8l5.5-5.5l1.42 1.41L7.83 7h5.67M6 18h2v2H6v-2z" fill="#626262" /></svg>
            </a>
            <a v-else href="#" class="" @click="markLog('hidden', {...props.rowData})">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M19 5L5 19" stroke="#626262" stroke-width="2" stroke-linecap="round" class="il-md-length-25 il-md-duration-2 il-md-delay-2" /><path d="M5 5l14 14" stroke="#626262" stroke-width="2" stroke-linecap="round" class="il-md-length-25 il-md-duration-2 il-md-delay-0" /></g></svg>
            </a>
          </p>
        </div>
      </template>
    </Vuetable>
    <VuetablePagination
      ref="pagination"
      :css="cssPages"
      @vuetable-pagination:change-page="onChangePage"
    />
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import DefaultTable from "@/components/rocket/CnfTableDefaults";
import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";
// import { Icon } from "@iconify/vue2";
import CommentField from "./ReconCommentTd.vue";

export default {
  // name: "rkt-logs-table",
  components: {
    Vuetable,
    VuetablePagination,
    // CommentField,
    // Icon
  },
  props:{
    // perPage: Number,
    // sortDefault: Array,
    // searchMinLenght: Number,
    apiurl: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      fields: [
        {
          name: CommentField,
        },
        {
          name: "actions",
          title: "",
        },
      ],
      css: DefaultTable.css,
      cssPages: DefaultTable.cssPagination,
      sortOrder: [
        {
          field: "createdAt",
          direction: "asc"
        }
      ],
      // search:{search: ""}
    };
  },

  methods: {
    reconHasCfdiMetadata(reconrow) {
      const { meta = {} } = reconrow;
      const { cfdi = null, cfdi_status = null } = meta;
      console.log("CFDI CHECK", reconrow, cfdi, cfdi_status);
      return cfdi != null;
    },
    onPaginationData(paginationData) {
      // this.$refs.pagination.setPaginationData(paginationData);
      // this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage(page) {
      // this.$refs.vuetable.changePage(page);
    },
    formatComment(rowData) {
      const { comment } = rowData;
      // console.log("***");
      return comment;
    },
    tryAutoAttachContact(data) {
      // Aqui no podemos porque no tenemos el reconid
      this.$emit("recon-attach-contact", data);
    },
    tryAutoUpdateRecon(data) {
      this.$emit("recon-attach-cfdiinfo", data);
    },
    markLog(status, rowData) {
      this.$emit("requestMarkReconComment", { status, id: rowData._id, parent: rowData.parent });
    },
    onReconLogRowClass(dataItem, index)  {
      const { meta = {} } = dataItem;
      const { status = "shown" } = meta;
      // console.log ("Rowclass", dataItem, meta, status)
      return (status !== "shown") ? "text-striked text-muted comment-row" : "";
    },
    refresh() {
      this.$refs.vuetable.refresh();
    }
  },
  mounted() {
  }
};

</script>
