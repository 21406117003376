<style scoped>
.dropdown-cell{
  /* background-color: red; */
  padding: 5px !important;
  min-height: 30px !important;
}
.dropdown-year{
  flex-basis: 66.666666% !important;
}
.dropdown-grid{
  min-width: 200px !important;
}
.dropdown-fullwidth{
  flex-basis: 100% !important;
}
.icon-align{
  vertical-align: sub !important;
}
</style>
<template>
  <div class="dropdown">
    <button class="btn btn-sm btn-light dropdown-toggle" :class="{'disabled': !disable}" type="button" data-toggle="dropdown" aria-expanded="false">
      <span class="hidden-xs-down">
        {{ title }}
      </span>
      <span class="hidden-sm-up">
        {{ responsiveYear }}
      </span>
    </button>
    <div class="dropdown-menu dropdown-grid" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 37px, 0px); top: 0px; left: 0px; will-change: transform;">
      <p class="dropdown-item dropdown-cell dropdown-year" href="#">
        <span class="title">
          <a href="#" @click="onYearClic(-1, ...arguments)">
            <Icon icon="ic:outline-arrow-left" />
          </a>
          {{ tempYear }}
          <a href="#" @click="onYearClic(+1, ...arguments)">
            <Icon icon="ic:outline-arrow-right" />
          </a>
        </span>
      </p>
      <a class="dropdown-item dropdown-cell" href="#" @click.prevent="onTodayClic">
        <span class="title">Hoy</span>
      </a>
      <a
        v-for="(it, idx) in months"
        :key="it"
        :class="{ active: idx==month-1 && year == tempYear }"
        class="dropdown-item dropdown-cell" href="#"
        @click="onMonthClic(idx + 1, ...arguments)"
      >
        <span class="title">{{ it }}</span>
      </a>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "YearMonthDropdown",
  components: {
    Icon,
  },
  props: {
    year: {
      type: Number,
      default: 2023
    },
    month: {
      type: Number,
      default: 1
    },
    disable:{
      type: Boolean,
      default: false
    },
    refresh: {
      type:Number,
      default: 0
    }
  },
  data() {
    return {
      months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      tempYear: this.year,
    };
  },
  computed: {
    title() {
      if (this.tempYear === this.year) {
        return `${this.months[this.month - 1]} ${this.tempYear}`;
      }
      return `${this.months[this.month - 1]} ${this.year}`;
    },
    responsiveYear() {
      const tempYear = `${this.tempYear}`.slice(-2);
      const currYear = `${this.year}`.slice(-2);
      if (this.tempYear === this.year) {
        return `${this.months[this.month - 1]} '${tempYear}`;
      }
      return `${this.months[this.month - 1]} '${currYear}`;
    },
  },
  watch: {
    year: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        this.tempYear = val;
      }
    },
    refresh: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        this.tempYear = this.year;
      }
    }
  },
  methods:{
    onYearClic(sum, ev) {
      // console.log(sum, ev);
      ev.stopPropagation(); // Evita que se quite el dropdown
      ev.preventDefault();  // Evita que se refresque la pagina
      // El padre decidira si nos cambia el prop o no
      const currYear = new Date().getFullYear();
      if (currYear < (this.tempYear + sum)) {
        return;
      }
      this.tempYear += sum;
    },
    onMonthClic(month, ev) {
      // Aqui el clic es directo al mes
      ev.preventDefault();  // Evita que se refresque la pagina
      // El padre decidira si nos cambia el prop o no
      this.$emit("dateChanged", this.tempYear, month, 1);
    },
    onTodayClic() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      this.$emit("dateChanged", year,  month, day);
    }
  }
};
</script>
