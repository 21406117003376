<style scoped>
.file-item {
  min-width: 50px;
  max-width: 80px;
  overflow: hidden;
  display: inline-block;
  margin: 3px;
  padding: 3px;
}
.file-badge{
  width: 15px;
  height: 15px;
  position: absolute;
  vertical-align: 0em;
  z-index: 999;
}
.file-item:hover .file-badge {
  /* Aparecer badge cuando el padre tiene :hover */
  opacity: 1.0 !important;
}
.file-badge:hover{
  /* Cambio de color en :hover del propio badge */
  background-color: #383f48 !important;
}
.file-item .file-name {
  display: block;
}
.file-item .file-date {
  display: block;
}
.file-item-icon {
  display: block;
  margin: auto;
  min-height: 4em;
  min-width: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
}
.file-item-icon-custom {
  min-height: 3.4em;
  height: 3.4em;
  min-width: fit-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}
.file-item:hover {
  background-color: #20202080;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.spinning {
  animation: rotation 2s infinite linear;
}
.file-droparea {
  background-color: rgba(218, 112, 214, 0.05);
  min-height: 40px;
  min-width: 50px;
  margin-top: 5px;
  border: 1px solid #55444a62;
}
.modal-visible {
  display: block;
}
.pre-upload-thumb {
  min-height: 60px;
  max-height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pre-upload-pdf {
  min-height: 60px;
  max-height: 100%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.recon-toolbar {
  display: flow-root;
}
.saldos-table > tbody > * > td
,.saldos-table > tbody > * > th{
  padding-top: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
}
.modal-print {
  color: black;
  z-index: 999;
}
@media print {
  p.bodyText {
    font-family: georgia, times, serif;
  }

  * {
    color: black;
  }
  .modal-content {
    max-width: 70vw;
  }
  .modal-print,
  .modal-dialog,
  .modal-content {
    z-index: 999;
    background-color: rgb(121, 121, 110);
    width: 100vw;
    height: 100vh;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 22pt;
    margin: auto;
    font-weight: bold;
  }
  p,
  i {
    font-size: 15pt;
    /* margin: 10pt; */
    padding-top: 15pt;
  }
  small {
    /* margin: 15pt; */
    /* padding: 15pt; */
  }
  hr {
    border-bottom: 1px solid black;
    max-width: 95%;
  }
}
.text-striked {
  text-decoration-line: line-through;
}
</style>
<template>
  <div class="box">
    <div class="box-header">
      <h4 class="box-title" :class="{ 'text-primary': isOpen }">
        <LiveEdit
          v-show="isOpen" :value="reconData.title" :max="256" :min="1"
          @valueChanged="onCommonChanged('title', ...arguments)"
        >
          C{{ reconData.recon_sequence }}:
        </LiveEdit>
        <span v-show="!isOpen">C{{ reconData.recon_sequence }}: {{ reconData.title }}</span>
      </h4>
      <div class="box-controls float-right">
        <div class="btn-group">
          <button
            class="btn btn-sm dropdown-toggle" :class="statusClass" type="button" data-toggle="dropdown"
            aria-expanded="true"
          >
            <span class="hidden-sm-up">
              <Icon :icon="statusIcons" :inline="true" class="text-white" />
            </span>
            <span class="hidden-xs-down">
              {{ statusText }}
            </span>
          </button>
          <div
            class="dropdown-menu " x-placement="bottom-start"
            style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;"
          >
            <a class="dropdown-item" href="#" @click="setStatus('open')">
              <Icon icon="material-symbols:lock-open-right-sharp" :inline="true" class="text-primary" />
              Abierta
            </a>
            <a class="dropdown-item" href="#" @click="setStatus('info')">
              <Icon icon="ic:sharp-incomplete-circle" :inline="true" class="text-info" />
              Incompleta
            </a>
            <a class="dropdown-item" href="#" @click="setStatus('waiting')">
              <Icon icon="ic:sharp-hourglass-top" :inline="true" class="text-warning" />
              En espera
            </a>
            <a class="dropdown-item" href="#" @click="setStatus('warn')">
              <Icon icon="material-symbols:warning-outline" :inline="true" class="text-danger" />
              Atención
            </a>
            <a class="dropdown-item" href="#" @click="setStatus('closed')">
              <Icon icon="material-symbols:lock-sharp" :inline="true" class="text-white" />
              Cerrada
            </a>
            <!-- <div data-v-f646b5ae="" class="dropdown-divider" />
            <a class="dropdown-item" href="#">
              <Icon icon="material-symbols:delete-sharp" :inline="true" />
              Basura
            </a> -->
          </div>
          <button type="button" class="btn btn-sm btn-default d-none d-sm-block float-right" @click.prevent="fillInitial">
            <Icon icon="ic:outline-refresh" />
          </button>
        </div>
        <button type="button" class="btn btn-sm btn-light" title="Copiar titulo" @click="copyTitleToClipboard">
          <Icon icon="clarity:copy-to-clipboard-line" :inline="true" />
        </button>
      </div>
    </div>
    <div class="box-body row">
      <div class="table-responsive col-md-12 col-lg-5" :class="{'box-outline-primary': isOpen}">
        <table class="table table-sm saldos-table">
          <thead>
            <tr>
              <th colspan="2" class="text-right pt-3 pr-1">
                <h4>Saldos de C{{ reconData.recon_sequence }} </h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class=" py-0">
              <th scope="row" class="text-right" style="white-space: nowrap;">
                (+) <span class="d-none d-xl-inline">Saldo </span>Inicial
              </th>
              <td class="col text-right pre-nowrap" :class="{ 'text-warning': remainingAmount < 0.02 }">
                <LiveEdit
                  v-show="isOpen" class="pre-nowrap fixed-font text-info" :value="remainingAmount"
                  :show-buttons="false" :min="0" :max="999999999"
                  @valueChanged="onAmountChanged('amount', ...arguments)"
                />
                <span v-show="!isOpen">{{ remainingAmount }}</span>
              </td>
            </tr>
            <tr class=" my-0">
              <th scope="row" class="text-right" style="white-space: nowrap;">
                (-) Pagos
              </th>
              <td class="col pre-nowrap fixed-font text-right">
                {{ paid }}
              </td>
            </tr>
            <tr class="bt-3 border-secondary py-0">
              <th scope="row" class="text-right">
                <strong>
                  (=) Saldo
                  <svg
                    v-if="pending < 0.0" title="Es negativo" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em"
                    height="1em"
                    style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"
                  >
                    <path
                      d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 0 1 0-96a48.01 48.01 0 0 1 0 96z"
                      fill="#e9ab2e"
                    />
                  </svg>
                </strong>
                <a title="Clic para copiar al portapapeles" href="#" @click="copyPending()">
                  <small>
                    <i class="mdi mdi-content-copy"></i>
                  </small>
                </a>
              </th>
              <td
                class="col pre-nowrap fixed-font text-right"
                :class="{ 'text-danger': pending > 0.0, 'text-warning': pending < 0.0, 'text-primary text-striked': pending === 0.0 }"
              >
                {{ pending }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="attachedCfdis.length > 0" class="row">
          <div class="col-12 border-top border-dark pt-3 ">
            <h6 class="text-right pr-1">
              <Icon icon="carbon:chart-relationship" class="text-muted" :rotate="1" /> CFDIs
            </h6>
            <p v-for="it in attachedCfdis" :key="it._id">
              <a
                href="#" class="text-muted" :class="{ 'text-striked': it.estado_sat == 'C' }"
                @click.prevent="redirectToCfdi(it.uuid)"
              >
                {{ it.uuid }}
              </a>
              <br>
              <strong>{{ it.rfc_emisor }}</strong>
              <span class="float-right text-info pr-1" :class="{ 'text-striked': it.estado_sat == 'C' }">
                $ {{ it.total_fmt }}
              </span>
              <br>
              {{ it.nombre_emisor }}
            </p>
          </div>
        </div>
        <div v-if="attachedBanklines.length > 0" class="row">
          <div class="col-12 border-top border-dark pt-3 ">
            <h6 class="text-right pr-1">
              <Icon icon="carbon:chart-relationship" class="text-muted" :rotate="1" /> Bancos
            </h6>
            <p v-for="it in attachedBanklines" :key="it._id">
              <span>
                {{ accountsAlias[it.srcaccount] ?? "" }}
              </span>
              <a href="#" class="text-purple" @click.prevent="redirectToBanks(it.hash)">
                {{ it.hash }}
              </a>
              <small class="float-right text-muted">{{ it.bankdate_fmt }}</small>
              <br>
              <small>Ref: {{ it.reference }}</small>
              <span class="float-right text-purple pr-1">$ {{ it.amount_fmt }}</span>
              <br>
              <small>
                {{ it.fullnotes_fmt }}
              </small>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-7">
        <div class="box box-solid no-print ">
          <div class="box-header with-border px-0">
            <h6 class="mb-2">
              Propósito:
              <span v-for="(t, index) in transformedTags" :key="index" :title="t.title" class="badge badge-dark ml-1 mb-1">
                <Icon v-if="istaxes" icon="game-icons:robber" />
                {{ t.desc ?? t }}
              </span>
            </h6>
            <small v-if="istaxes" class="text-warning">
              <Icon icon="material-symbols:warning" :inline="true" /> Pago de impuestos, no usar para otra cosa.
            </small>
            <div class="btn-group recon-toolbar py-1">
              <!-- <button type="button" class="btn btn-sm btn-default">investig</button> -->
              <!-- <button type="button" class="btn btn-sm btn-default" title="Bloquear">
                <Icon icon="fa-solid:lock" />
              </button> -->
              <div v-show="isOpen" v-if="istaxes" class="btn-group">
                <button
                  class="btn btn-sm btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                  aria-expanded="true"
                >
                  Tipo
                  <Icon v-if="istaxes" icon="game-icons:robber" />
                </button>
                <div
                  class="dropdown-menu " x-placement="bottom-start"
                  style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;"
                >
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-sal')">
                    ISR Salarios
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-ret')">
                    ISR Retenciones
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-arr')">
                    ISR Arrendamientos
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-asim')">
                    ISR Asimilados
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-hono')">
                    ISR Honorarios
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isr-pmoral')">
                    ISR Personas Morales
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('iva-ret')">
                    IVA Retenciones
                  </a>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('iva')">
                    IVA Causado
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('isertp')">
                    ISERTP
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click.prevent="setTaxTag('imss')">
                    IMSS/INFONAVIT
                  </a>
                </div>
              </div>
              <button
                v-if="!istaxes" type="button" class="btn btn-sm btn-default" title="Imprimir un recibo"
                @click="isPrintingReceipt = true;"
              >
                <Icon icon="fa-solid:receipt" />
              </button>
              <ReconsBtnTags
                v-show="isOpen"
                title="Propósito"
                icon="fluent:target-arrow-20-filled"
                @tagClicked="onUpdateTagRecon"
              />
              <YearMonthPicker
                class="btn-group"
                :disable="isOpen"
                :year="filterDateYear"
                :month="filterDateMonth"
                :applied="filterDateApply"
                :refresh="filterDateRefresh"
                @dateChanged="onDateFilterChanged"
              />
              <div class="btn-group">
                <button
                  type="button" class="btn btn-sm btn-default dropdown-toggle" title="Compartir"
                  data-toggle="dropdown" :class="{ 'text-warning': shareCount > 0 }"
                >
                  <Icon v-if="shareCount > 0" icon="fa-solid:users" />
                  <Icon v-else icon="fa-solid:user" />
                </button>
                <div class="dropdown dropdown-menu dropdown-sm">
                  <h6 class="dropdown-header ">
                    <span class="badge badge-pill badge-primary">Compartir con:</span>
                  </h6>
                  <a
                    v-for="item in sharedUsers" :key="item.id" class="dropdown-item"
                    :class="{ 'text-primary': item.sharing }" href="#" @click.prevent="toggleSharing(item)"
                  >
                    <svg
                      v-if="item.sharing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1em" height="1em"
                      preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"
                    >
                      <g fill="currentColor">
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </g>
                    </svg>
                    {{ item.email }}
                  </a>
                  <!-- <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="box-body px-1 ">
            <!-- <div class="border-left border-dark pl-2 py-1 mb-2"> -->
            <h6 v-if="attachedFiles.length > 0">
              Adjuntos ({{ attachedFiles.length }})
            </h6>
            <div v-for="it in attachedFiles" :key="it._id" class="file-item" data-truncate="">
              <div v-if="it.customIcon == 'xml'" class="file-badge" style="opacity: 0.1;">
                <a href="#" class="" title="Volver a mandar al bot XML" @click="triggerFileAutomation(it)">
                  <Icon
                    icon="ei:refresh" inline="true" class="ref-icon"
                    style="vertical-align: -0.0em; margin-left: 0.05em;"
                  />
                </a>
              </div>
              <a href="#" :title="it.ago" @click="viewAttachment(it._id)">
                <svg
                  v-if="it.customIcon == 'xml'" id="svg5" class="file-item-icon-custom" viewBox="0 0 24 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"
                >
                  <defs id="defs2" />
                  <g id="layer1" transform="translate(-84.35927,-279.44465)">
                    <path
                      id="path9910"
                      d="m 99.355455,279.54565 h -9.577754 c -1.322399,0 -2.394421,1.072 -2.394439,2.39441 v 19.15553 c 1.8e-5,1.32241 1.07204,2.39441 2.394439,2.39441 h 14.366629 c 1.3224,0 2.39442,-1.072 2.39444,-2.39441 v -14.36663 z m -6.916538,19.20576 -0.436972,-0.87517 c -0.179594,-0.33755 -0.294527,-0.589 -0.429822,-0.86919 h -0.01538 c -0.09937,0.27874 -0.221495,0.53041 -0.373532,0.86919 l -0.40109,0.87517 H 89.53103 l 1.401943,-2.4483 -1.351662,-2.39083 h 1.257082 l 0.423805,0.88353 c 0.144888,0.29322 0.251404,0.53037 0.366337,0.80331 h 0.01538 c 0.114933,-0.30905 0.208327,-0.52434 0.330455,-0.80331 l 0.408239,-0.88353 h 1.248702 l -1.363635,2.3621 1.434269,2.47703 z m 6.916538,-10.82524 h -1.197222 v -5.98611 l 5.986097,5.98611 z m 5.121865,10.83126 h -2.89177 v -4.86108 h 0.91825 V 297.99 h 1.97469 v 0.76743 z m -5.445524,-0.0226 -0.07158,-1.85178 c -0.0276,-0.66231 -0.04027,-1.32507 -0.04254,-1.98797 h -0.02353 c -0.156653,0.63072 -0.335976,1.25557 -0.538,1.87322 l -0.58819,1.88757 h -0.854477 l -0.516466,-1.87322 c -0.173214,-0.62263 -0.319504,-1.25241 -0.4386,-1.88757 h -0.01131 c -0.0267,0.6538 -0.05036,1.39978 -0.08602,2.00236 l -0.08597,1.83739 h -1.01256 l 0.308283,-4.83782 h 1.457143 l 0.473438,1.61495 c 0.15068,0.5595 0.302491,1.16313 0.408872,1.72979 h 0.02353 c 0.140318,-0.58191 0.293351,-1.16069 0.459072,-1.73595 l 0.516461,-1.60779 h 1.428518 l 0.22887,4.15971 0.0371,0.67806 h -1.069822 z"
                      fill="currentColor" style="stroke-width:1.19722"
                    />
                    <g id="g11794" transform="matrix(0.87414082,0,0,0.87414082,9.7723046,35.858261)" />
                  </g>
                </svg>

                <Icon v-if="it.iconName.length > 0" class="file-item-icon" :icon="it.iconName" />
                <small class="file-name truncate" :title="it.originalname">{{ it.originalname }} </small>
                <small class="file-date" style="white-space: nowrap;">{{ it.date }} {{ it.time }}</small>
              </a>
            </div>
            <Droparea v-if="isOpen" class="file-droparea p-2" @fileDropped="onFileDropped">
              <!-- <img class="w-80x80" src="" alt="..." /> -->
              <!-- <div class="box-body"> -->
              <p class="text-info">
                <svg
                  width="1.5em" height="1.5em" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" style="vertical-align: -0.4em;"
                  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                >
                  <path d="M13 19v-4h3l-4-5l-4 5h3v4z" fill="currentColor" />
                  <path
                    d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3c0-1.404 1.199-2.756 2.673-3.015l.581-.102l.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.611 5.757 9.15C3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z"
                    fill="currentColor"
                  />
                </svg>
                Cargar archivo...
              </p>
              <!-- <small class="text-fader"> -->
              <!-- 406 KB -->
              <!-- </small> -->
              <!-- </div> -->
            </Droparea>
          </div>
          <!-- /.box-body -->
        </div>
      </div>

      <div class="table-responsive col-12 no-print">
        <!-- v-show="this.reconData.rlogs.length > 0 || this.forceViewLogs" -->
        <ReconCommentsTable
          ref="reconLogsTable"
          :apiurl="currentReconLogsUrl"
          @requestMarkReconComment="onRequestMarkReconComment($event)"
          @recon-attach-contact="onTryAutoAttachContact"
          @recon-attach-cfdiinfo="updateReconFromCfdi"
        />
      </div>
      <div v-if="privileges && (privileges.indexOf('RECONS-EDIT') >=0) && isOpen" class="col-12 no-print">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <label class="control-label">Comentario de los pagos... </label>
              <label
                v-if="comment.trim().length>0 && comment.trim().length<5"
                class="control-label text-danger"
              >
                muy corto
              </label>
              <textarea
                ref="comment-area"
                v-model="comment"
                tabindex="1"
                class="form-control" placeholder="" rows="6"
                :class="{ 'border-danger': comment.trim().length>0 && comment.trim().length<5 }"
                @keyup.ctrl.enter="pushComment()"
              ></textarea>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class=" form-group">
              <label
                data-toggle="tooltip" data-placement="top" class="flexbox"
                title="Si hay un monto a pagar, se captura para que se considere el pago."
              >
                Monto:
                <a href="#" class="btn btn-xs btn-info" tabindex="3" @click="commentAmount = pending">Todo</a>
              </label>
              <input
                v-model="commentAmount"
                type="text"
                tabindex="2"
                value=""
                placeholder="000.00" class="form-control" :class="{ 'border-danger': !validAmount }"
                @blur="sanitizeAmount()"
                @keyup.ctrl.enter="pushComment()"
              >
            </div>
          </div>
          <div class="col-2">
            <h6>&nbsp;</h6>
            <button
              tabindex="3"
              class="btn btn-sm btn-info"
              :class="{ 'btn-secondary': comment.trim().length<5 }"
              :disabled="comment.trim().length<5 || !validAmount"
              @click="pushComment()"
            >
              <i class="fa fa-fw fa-save" aria-hidden="true"></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL DE ARCHIVOS -->
    <div
      ref="file-upload-modal"
      class="modal fade bs-example-modal-lg"
      :class="{'show modal-visible': isPreUploading}" tabindex="-1"
      role="dialog" aria-labelledby="myLargeModalLabel"
      @keydown.esc="onFilePreuploadCancel"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- <div class="modal-header">
            <h4 class="modal-title" id="myLargeModalLabel">Cargando archivo anexo</h4>
            <button @click="onFilePreuploadCancel" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div> -->
          <div class="modal-body row">
            <div class="col-md-6">
              <embed v-if="preUploadThumb.length<100" class="pre-upload-pdf" :src="preUploadB64" type="application/pdf">
              <img v-if="preUploadThumb.length>100" class="pre-upload-thumb" :src="preUploadThumb">
              <p class="text-right text-small text-muted">
                {{ preUploadHumanSize }}
              </p>
            </div>
            <div class="col-md-6">
              <h4 id="myLargeModalLabel" class="">
                Cargando {{ preUploadFilename }}
              </h4>
              <h5 class="text-muted p-1">
                <!-- Archivo: {{ this.preUploadFilename }} -->
                <!-- <LiveEdit
                  class="pre-nowrap fixed-font text-info"
                  :value="this.preUploadFilename"
                  :show-buttons="false"
                  :min="0"
                  :max="999999999"
                  @valueChanged="true"
                /> -->
              </h5>
              <div v-if="preUploadThumb.length>100" class="form-group p-2">
                <div class="radio">
                  <input id="reduced" v-model="preUploadDoRescale" name="group1" type="radio" value="true" checked>
                  <label for="reduced">Reducir tamaño ({{ preUploadHumanSize }})</label>
                </div>
                <div class="radio">
                  <input id="original" v-model="preUploadDoRescale" disabled name="group1" type="radio" value="false">
                  <label for="original">Subir original (<span :class="[preUploadSize>preUploadWarnSize?'text-danger text-bold':'']">{{ preUploadHumanSize }}</span>)</label>
                </div>
              </div>

              <p class="p-1">
                Comentario:
                <textarea v-model="preUploadComment" tabindex="1" placeholder="" rows="2" class="form-control"></textarea>
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-danger waves-effect text-left" data-dismiss="modal" @click="onFilePreuploadCancel">
              Cancelar
            </button>
            <button :disabled="isSavingFile" type="button" class="btn btn-sm btn-primary float-right " @click="onFileConfirm">
              <svg
                v-if="isSavingFile" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" style="vertical-align: -0.125em;"
                width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
              >
                <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z" opacity=".5" fill="currentColor" />
                <path d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z" fill="currentColor">
                  <animateTransform attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="1s" repeatCount="indefinite" />
                </path>
              </svg>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      id="modal-fill"
      ref="printReceiptModal"
      class="modal modal-fill fade modal-print text-default"
      :class="{'show': isPrintingReceipt}" data-backdrop="false" tabindex="-1" style="display: block;" aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header no-print">
            <!-- <h5 class="modal-title">Recibo</h5> -->
            <button type="button" class="close" data-dismiss="modal" @click="isPrintingReceipt=false">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="text-default">
              Recibo C{{ reconData.recon_sequence }}
            </h4>
            <p>
              El que recibe, en pleno uso de sus facultades
              y por voluntad propia hago constar que en el lugar y fecha
              mencionados más adelante, recibí de:
            </p>
            <hr>
            <p>
              a mi entera satisfacción, la cantidad de (con núm y letra):
              <br>
              <strong>
                $ {{ remainingAmount }}
                {{ remainingAmountText }} pesos _____ /100 MXN
              </strong>
            </p>
            <p>
              por concepto de: <i>{{ reconData.title }}</i>
            </p>
            <hr>
            <small>
              Aceptando de que ante un inclumplimiento del contrato, trabajo, producto o servicio:
            </small>
            <small>
              <br> 1. se podrá utilizar como pagaré siendo <strong> totalmente negociable</strong>
              <br> 2. se pagará en el lugar y mecanismo que el el que firma como "Entrega", indique.
            </small>
            <p>Lugar y Fecha: ____________________________________________________</p>
            <p>
              RECIBE (nombre, firma): ____________________________________________________
            </p>
          </div>
          <div class="modal-footer no-print">
            <button
              type="button"
              class="btn btn-bold btn-pure btn-secondary" data-dismiss="modal" @click="dismissPrintReceipt"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-bold btn-pure btn-primary float-right"
              @click="printReceipt"
            >
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { mapState } from "vuex";
import { parseISO, format, formatDistance } from "date-fns";
import esmx from "date-fns/locale/es";
import { Icon } from "@iconify/vue2";
import { formatMoney } from "accounting-js";
// import Toggle from "@/components/Toggle.vue";
// import LiveSearch from "@/components/LiveSearch.vue"; // muestra la lista de busqueda
import LiveEdit from "@/components/LiveEdit.vue"; // solo edita y avisa
import Droparea from "@/components/Droparea.vue";
import YearMonthPicker from "@/components/YearMonthPicker.vue";
import UsersApi from "@/services/Users.service";
import ContactsApi from "@/plugins/Contacts/Contacts.service";

import ReconCommentsTable from "./ReconCommentsTable.vue";
import ReconsBtnTags from "./ReconsBtnTags.vue";
import ReconsApi from "./Recons.service";

const writtenNumber = require("written-number");

writtenNumber.defaults.lang = "es";

export default {
  components: {
    ReconsBtnTags,
    YearMonthPicker,
    LiveEdit,
    Droparea,
    ReconCommentsTable,
    Icon,
    // LiveSearch,
    // Toggle
  },
  props: {
    // apiurl: {
    //   type: String,
    //   default: ""
    // },
    // reconSeq: {
    //   type: Number,
    //   default: -1
    // },
    refresh: {
      type: Number,
      default: 0
    },
    currReconId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      icons: {
        "image/jpg": "bx:bxs-file-jpg",
        "image/jpeg": "bx:bxs-file-jpg",
        "image/png": "bx:bxs-file-png",
        "application/pdf": "bx:bxs-file-pdf",
        "text/xml": "bx:bx-file  ",
        "application/xml": "bx:bx-file",
        "binary/octet-stream": "bx:bx-file-blank"
      },
      regimen_str: {
        601: "General de Ley Personas Morales",
        603: "P. Morales con Fines no Lucrativos",
        605: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
        606: "Arrendamiento",
        607: "R. de Enajenación o Adquisición de Bienes",
        608: "Demás ingresos",
        609: "Consolidación",
        610: "Residentes en el Extranjero sin Establecimiento Permanente en México",
        611: "Ingresos por Dividendos (socios y accionistas)",
        612: "P. Físicas con Actividades Empresariales y Profesionales",
        614: "Ingresos por intereses",
        615: "R. de los ingresos por obtención de premios",
        616: "Sin obligaciones fiscales",
        620: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        621: "Incorporación Fiscal",
        622: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        623: "Opcional para Grupos de Sociedades",
        624: "Coordinados",
        625: "Act. Empresariales con ingresos a través de Plataformas Tecnológicas",
        626: "Simplificado de Confianza",
        628: "Hidrocarburos",
        629: "Regs. Fiscales Preferentes y de las E. Multinacionales",
        630: "Enajenación de acciones en bolsa de valores",
      },
      availableUsersToShare: [],
      reconData: {
        recon_sequence: -1,
        tags: []
      },
      attachedFiles: [],
      sortDefault: [
        {
          field: "createdAt",
          sortField: "createdAt",
          direction: "desc"
        }
      ],
      comment: "",
      commentAmount: "",
      validAmount: true,

      isPreUploading: false,
      isSavingFile: false,
      preUploadB64: "",
      preUploadComment: "",
      preUploadThumb:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/DfGAAGNwIzdH8kZAAAAABJRU5ErkJggg==",
      preUploadMimeType: "image/jpeg",
      preUploadFilename: "SinNombre.jpg",
      preUploadDescription: "SinNombre.jpg",
      preUploadSize: 0,
      preUploadWarnSize: 1024 * 1024 * 2.5,
      preUploadDoRescale: true,

      isPrintingReceipt: false,

      contactSearchResults: [],
      taxTagNames: {
        "isr-sal": "ISR Salarios",
        "isr-ret": "ISR Rentenciones",
        "isr-arr": "ISR Arrendamientos",
        "isr-asim": "ISR Asimilados",
        "isr-hono": "ISR Honorarios",
        "isr-pmoral": "ISR Personas Morales",
        "iva-ret": "IVA Retenciones",
        iva: "IVA Causado",
        isertp: "ISERTP",
        imss: "IMSS/INFONAVIT",
        default: ""
      },
      transformedTags:[],
      accountsAlias: {
        "062650163410076195": "Afirme 6195",
        "062650163410006149": "Afirme 6149",
        376702480221008: "Amex 1008"
      },
      filterDateApply: false,
      filterDateYear: new Date().getFullYear(),
      filterDateMonth: 1,
      filterDateRefresh:0
    };
  },
  computed: {
    ...mapState(["privileges", "user", "API_ROOT"]),
    istaxes() {
      const { tags = [] } = this.reconData;
      if (tags.indexOf("impuestos") > -1) {
        return true;
      }
      return false;
    },
    currentReconLogsUrl() {
      const { _id = null } = this.reconData;
      if (!_id) {
        return "";
      }

      const url = `${ReconsApi.baseurl()}/${_id}/logs`;
      console.log(url);
      return url;
    },
    reconId() {
      const { _id = "_" } = this.reconData;
      return _id;
    },
    uuid() {
      const { cfdi_uuid: uuid = "xxx" } = this.reconData;
      return uuid;
    },
    cfdi_total() {
      const { cfdi_total: total = null } = this.reconData;
      return total;
    },
    statusClass() {
      const { status = "open" } = this.reconData;
      return {
        "btn-primary": status === "open",
        "btn-info": status === "info",
        "btn-warning": status === "waiting",
        "btn-danger": status === "warn",
        "btn-default": status === "closed"
      };
    },
    statusText() {
      const st = {
        open: "Abierta",
        closed: "Cerrada",
        info: "Incompleta",
        warn: "Atención",
        waiting: "En espera"
      };
      const { status = "opens" } = this.reconData;
      return st[status] ?? "No definido";
    },
    remainingAmount() {
      let { amount = 0.0 } = this.reconData;
      amount = parseFloat(amount) || 0;
      return amount.toFixed(2);
    },
    remainingAmountText() {
      let { amount = 0.0 } = this.reconData;
      amount = parseFloat(amount) || 0;
      return writtenNumber(amount.toFixed(2));
    },
    pending() {
      let { pend = 0.0 } = this.reconData;
      pend = parseFloat(pend) || 0;
      return pend.toFixed(2);
    },
    paid() {
      let { paid = 0.0 } = this.reconData;
      paid = parseFloat(paid) || 0;
      return paid.toFixed(2);
    },
    isOpen() {
      const { status } = this.reconData;
      return status !== "closed";
    },
    sharedUsers() {
      // // Usuarios con acceso permitido en este momento
      const { users: currentUsers = [] } = this.reconData;
      // console.log("USErs", currentUsers, this.availableUsersToShare);
      const currentUserIds = currentUsers.map(it => it._id);
      const sharedUsers = this.availableUsersToShare.map(it => {
        const { id, email, name } = it;
        // console.log("SH", id, currentUsers);
        const sharing = currentUserIds.indexOf(id) > -1;
        return {
          id,
          email,
          name,
          sharing
        };
      });
      return sharedUsers;
    },
    shareCount() {
      const sharing = this.sharedUsers.filter(it => it.sharing);
      return sharing.length;
    },
    preUploadHumanSize() {
      return ReconsApi.humanFileSize(this.preUploadSize);
    },
    contact() {
      // Solo para garantizar que algunos campos no son undefined
      const { contact = {} } = this.reconData;
      const {
        name = "", email = "", rfc = "", regimen = ""
      } = contact;
      return {
        name, email, rfc, regimen, regimen_str: this.regimen_str[regimen]
      };
    },
    attachedCfdis() {
      const { cfdis = [] } = this.reconData;
      return cfdis.map(it => {
        const { total = "0" } = it;

        let total_fmt = parseFloat(total) || 0;
        total_fmt = formatMoney(total, { symbol: "", format: "%v" });
        return { ...it, total_fmt };
      });
    },
    attachedBanklines() {
      const { banklines = [] } = this.reconData;
      return banklines.map(it => {
        const { bankdate = null, amount = 0, fullnotes = "" } = it;

        let amount_fmt = parseFloat(amount) || 0;
        amount_fmt = formatMoney(amount_fmt, { symbol: "", format: "%v" });
        const fullnotes_fmt = `${fullnotes.substring(0, 150)}${fullnotes.length > 150 ? "..." : ""}`;

        if (!bankdate) {
          return {
            ...it,
            bankdate_fmt: "-",
            amount_fmt,
            fullnotes_fmt
          };
        }
        const bankdateOrig = parseISO(bankdate);
        const bankdate_fmt = format(bankdateOrig, "d LLL, yyyy", { locale: esmx });
        return {
          ...it,
          bankdate_fmt,
          amount_fmt,
          fullnotes_fmt
        };
      });
    },
    statusIcons() {
      const { status } = this.reconData;
      const icon2status = {
        open: "material-symbols:lock-open-right-sharp",
        info: "ic:sharp-incomplete-circle",
        waiting: "ic:sharp-hourglass-top",
        warn: "material-symbols:warning-outline",
        closed: "material-symbols:lock-sharp",
      };
      return icon2status[status] ?? icon2status.open;
    }
  },

  watch: {
    refresh: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.fillInitial();
      }
    },
    // eslint-disable-next-line no-unused-vars
    commentAmount(v, oldv) {
      const validFloat = /^-?[0-9]+(e[0-9]+)?(\.)?(\.[0-9]+)?$/;
      this.validAmount = validFloat.test(this.commentAmount) || this.commentAmount === "";
    },
    currReconId: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Changed ROUTE", old, val);
        this.fillInitial();
      }
    }
  },
  methods: {
    copyTitleToClipboard(ev) {
      ev.preventDefault();
      const title = `C${this.reconData.recon_sequence} ${this.reconData.title}`;
      navigator.clipboard.writeText(this.sanitizeStr(title));
      // console.log("C", this.reconData.recon_sequence, ":", this.reconData.title);
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Titulo copiado a portapapeles",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    },
    sanitizeStr(str) {
      if (typeof str !== "string") {
        return "";
      }

      // eslint-disable-next-line quotes
      const validChars = ` abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_`;
      let strSanitized = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      strSanitized = strSanitized.split("")
        .filter((char) => validChars.indexOf(char) > -1)
        .join("")
        .replace(/ +(?= )/g, "") // Quita los dobles espacios
        .trim();
      return strSanitized;
    },
    transformTags(tags, type) {
      const reconsTypeCobrar = {
        subsidio: {
          desc: "Subsidios/Apoyos",
          title: "CONACYT, INADEM y convenios asociados con las universidades"
        },
        ventas:{
          desc: "Ventas",
          title: "Ventas reales, no subsidios federales"
        },
        intra: {
          desc: "Intracuentas",
          title: "Pagos a nosotros mismos, entre cuentas"
        },
        otro:{
          desc: "Otros",
          title: "Rara vez nos reembolsan, hay errores, se inyecta capital o cobramos préstamos temporales"
        },
        default: {
          desc: "",
          title: ""
        }
      };
      const reconsTypePagar = {
        becas: {
          desc: "Becas",
          title: "Becas y otros apoyos estudiantiles."
        },
        especial:{
          desc: "Bonos/Préstamos",
          title: "Bonos inusuales, préstamos o festejos."
        },
        investigacion:{
          desc:"Investigación & Desarrollo",
          title: "Insumos para experimentar y mejorar, herramientas, cómputo, electrónica y a veces carpintería/herrería."
        },
        marketing: {
          desc:"Marketing (y comidas)",
          title: "Impresión, congresos, publicidad y comidas de cortesía."
        },
        sueldos: {
          desc: "Sueldos y salarios",
          title: "Nomina, honorarios."
        },
        operativos: {
          desc:"Serv. esenciales (CFE, etc)",
          title: "Renta, CFE, tel, aseo."
        },
        intra:{
          desc: "Intracuentas",
          title: "Entre cuentas propias"
        },
        construccion:{
          desc: "Herramientas y construcción",
          title: "Mejoras y gastos de obra civil, electricidad y muebles."
        },
        consumibles:{
          desc: "Consumibles",
          title: "Papel, toner, agua, jabon, etc.",
        },
        couriers:{
          desc:"Envios de objetos",
          title: "Fletes, mudanzas, DHL, Fedex, UPS (EXCEPTO impuestos)."
        },
        instalacion:{
          desc: "Extras de instalaciones",
          title: "En instalación: cables, partes faltantes.",
        },
        impuestos: {
          desc: "Impuestos o com. bancarias",
          title: "IVA, ISR, IMSS, Estatales, etc."
        },
        proyectosx:{
          desc: "Inversión a proy. especial",
          title: "Inversiones especiales: PEI, INADEM, y otros proyectos especiales."
        },
        produccion:{
          desc: "Por producción para venta",
          title: "Insumos, partes, circuitos, carpintería y herrería para producir."
        },
        viajes:{
          desc: "Viático, hotel o pasaje",
          title: "Vuelos, hoteles, comidas de mkt o traslados de gente."
        },
        deuda:{
          desc: "Créditos o adeudos",
          title: "Créditos bancarios, adeudos por ajustes o pagos de daños a terceros."
        },
        mezclado:{
          desc: "Mezclado/Otros",
          title: "No deberías tener de estos, distorsiona las cuentas."
        },
        default: {
          desc: "",
          title: ""
        }
      };
      const { taxTagNames = {} } = this;
      return tags.map(tag => {
        if (type === "porcobrar") {
          return reconsTypeCobrar[tag] ?? reconsTypeCobrar.default;
        }
        if (type === "porpagar") {
          if (taxTagNames[tag]) {
            return taxTagNames[tag] ?? taxTagNames.default;
          }
          return reconsTypePagar[tag] ?? reconsTypeCobrar.default;
        }
        return "";
      });
    },
    fillInitial() {
      // console.log("Refilling from server", this.currReconId);
      const { currReconId: reconIdSeq } = this;
      ReconsApi.findOneByIdSeq(reconIdSeq)
        .then(res2 => {
          // console.log("RES", res2);
          this.reconData = res2;
          const {
            _id: reconid, tags = [], type = "", accountingdate = ""
          } = this.reconData ?? {};
          this.filterDateYear = new Date(accountingdate).getFullYear();
          this.filterDateMonth = new Date(accountingdate).getMonth() + 1;
          this.filterDateApply = !!accountingdate;
          this.filterDateRefresh += 1;
          this.transformedTags = this.transformTags(tags, type);
          return ReconsApi.getFilesForId(reconid);
        })
        .then(res => {
          // console.log("Files found:", res);
          const transf = res.map(it => {
            const dateOrig = parseISO(it.createdAt);
            const { contentType = "binary/octet-stream" } = it;
            let iconName = this.icons["binary/octet-stream"];
            if (Object.keys(this.icons).indexOf(contentType) > -1) {
              iconName = this.icons[contentType];
            }
            let customIcon = "";
            if (contentType.endsWith("xml")) {
              iconName = "";
              customIcon = "xml";
            }
            return {
              ...it,
              date: format(dateOrig, "dd MMM, yy", { locale: esmx }),
              time: format(dateOrig, "HH:mm"),
              iconName,
              ago: `Hace ${formatDistance(dateOrig, new Date(), {
                locale: esmx
              })}`,
              customIcon
            };
          });
          this.attachedFiles = transf;
        });
      this.preUploadThumb = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/DfGAAGNwIzdH8kZAAAAABJRU5ErkJggg==";
      this.preUploadDoRescale = true;
      this.isPreUploading = false;
      this.isSavingFile = false;
    },
    setStatus(status) {
      // console.log("Setting status to", status);

      const { _id: reconid } = this.reconData;
      const vm = this;
      // const status = this.isOpen ? "closed" : "open";
      ReconsApi.updateById(reconid, { status })
        .then(() => {
          vm.$emit("status-modified", this.reconData);
          this.$emit("requestRefresh");
        });
    },
    pushComment() {
      // console.log("CRE0", this.reconData._id, this.comment, this.commentAmount);
      const comment = this.comment.trim();
      if (comment.length < 4) return;

      ReconsApi.pushCommentToId(
        this.reconData._id,
        this.comment,
        this.commentAmount
      )
        .then(() => {
          // console.log("Push RES Com", res, this.$refs.reconLogsTable);
          this.$refs.reconLogsTable.refresh();
          this.forceViewLogs = true;
          this.$emit("comment-added", this.reconData);
          this.$emit("requestRefresh", this.reconData);
          this.comment = "";
          this.commentAmount = 0;
          this.fillInitial();
        })
        .catch(err => {
          console.log(err);
        });
    },
    copyPending() {
      console.log("Copy pending", this.reconData.pend, this.$copyText);
      this.$copyText(this.reconData.pend).then(
        e => {
          // alert("Copied");
          console.log(e);
        },
        e => {
          // alert("Can not copy");
          console.log(e);
        }
      );
    },
    triggerFileAutomation(fileitem) {
      console.log("Re-trigger file automations", fileitem);
      ReconsApi.triggerFileAutomation(this.reconId, fileitem)
        .then(res => {
          console.log("Auto", res);
        })
        .catch(err => {
          console.log("Auto Err", err);
        });
    },
    onTryAutoAttachContact(ev) {
      ReconsApi.autoAttachContactToRecon(this.reconId, ev)
        .then(() => {
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Contacto asociado",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
          this.fillInitial();
          this.$refs.reconLogsTable.refresh();
        })
        .catch(err => {
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo asociar el contacto",
            text: err,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
        });
    },
    updateReconFromCfdi(ev) {
      console.log("GOT CFDI Data", ev, this.reconData);
      const {
        cfdi_emite = "",
        cfdi_recibe = "",
        cfdi_rfcemite = "",
        cfdi_rfcrecibe = "",
        cfdi_metodopago = "",
        cfdi_formapago = "",
        cfdi_total = "",
        cfdi_uuid = ""
      } = ev;
      const query = {
        cfdi_emite,
        cfdi_recibe,
        cfdi_rfcemite,
        cfdi_rfcrecibe,
        cfdi_metodopago,
        cfdi_formapago,
        cfdi_total,
        cfdi_uuid
      };

      ReconsApi.updateById(this.reconId, query)
        .then(() => {
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Información de CFDI asociada",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
          this.fillInitial();
          this.$refs.reconLogsTable.refresh();
        })
        .catch(err => {
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo asociar la información de CFDI",
            text: err,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
        });
    },
    onRequestMarkReconComment(ev) {
      console.log("FIX ", ev);
      ReconsApi.markReconComment(ev)
        .then(res => {
          console.log("OK ", res);
          this.$emit("requestRefresh", this.reconData);
          this.fillInitial();
          this.$refs.reconLogsTable.refresh();
        })
        .catch(err => {
          console.log("ERR ", err);
        });
    },
    onCommonChanged(field, value) {
      // console.log("FieldVal", field, value);
      // Beware some fields are forbidden to modify directly any invalid
      // query will be silently ignored by API and returning '200 OK'.
      this.$emit("singleReconFieldChanged", this.reconData._id, field, value);
    },
    onAmountChanged(field, value) {
      // console.log("Initial amount changed:", field, value);
      // Se eliminan caracteres raros porque producen error y se va a 0
      let saneVal = value.replace(/,/g, "").replace(/\$/g, "");
      saneVal = parseFloat(saneVal);
      this.$emit("singleReconFieldChanged", this.reconData._id, field, saneVal);
      this.fillInitial();
    },
    onFileDropped(f) {
      // console.log("[RECON] FILE DROP ", f.name);
      this.isPreUploading = true;
      this.$refs["file-upload-modal"].focus();
      ReconsApi.processFileUpload(f).catch(e => {
        console.log("FILE DROP PREUPLOAD FAILED ", e);
        this.isPreUploading = false;
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "Tipo de archivo no admitido",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
      });
    },
    onFilePreuploadCancel() {
      console.log("FILE DROP CANCELLED");
      this.isPreUploading = false;
      this.preUploadComment = "";
    },
    onFileConfirm() {
      console.log("FILE DROP CONFIRM");
      const { _id: reconid } = this.reconData;
      this.isSavingFile = true;
      // If upload an image, then upload the thumbnail
      if (this.preUploadMimeType.startsWith("image/")) {
        ReconsApi.attachFile(
          reconid,
          this.preUploadFilename,
          this.preUploadMimeType,
          this.preUploadThumb,
          this.preUploadComment
        )
          .then(() => {
            console.log("TODO OK guardando la imagen");
            this.fillInitial();
            this.preUploadComment = "";
          })
          .catch(() => {
            console.log("ERROR  guardando la imagen");
          });
        return;
      }
      // Not an image: xml, pdf
      // eslint-disable-next-line no-unused-vars
      const result = ReconsApi.attachFile(
        reconid,
        this.preUploadFilename,
        this.preUploadMimeType,
        this.preUploadB64,
        this.preUploadComment
      )
        .then(() => {
          console.log("TODO OK guardando archivo (no imagen)");
          this.fillInitial();
          this.preUploadComment = "";
        })
        .catch(err => {
          console.log("ERROR  guardando archivo (no imagen)", err);
        });
    },
    viewAttachment(fileid) {
      // ReconsApi.getSignedDownloadUrl(this.reconId, fileid, "direct");
      const directLink = `${this.API_ROOT}/api/recons/${this.reconId}/files/${fileid}?view=direct`;
      // console.log("Direct Link", directLink);
      window.open(directLink);
    },
    sanitizeAmount() {
      // console.log("sanitizin in", typeof this.commentAmount);
      let { commentAmount: amount = "0.0" } = this;
      amount = `${amount}`; // Force to be string
      const validFloat = /^-?[0-9]+(e[0-9]+)?(\.)?(\.[0-9]+)?$/;
      this.commentAmount = amount.replace(/[^0-9\\.]+/g, "");
      this.validAmount =        validFloat.test(this.commentAmount) || this.commentAmount === "";
    },
    toggleSharing(user) {
      // contiene el actual status de sharing y hay que invertirlo
      // console.log("Toggle sharing of:", user);
      const { _id = null } = this.reconData;
      const vm = this;
      ReconsApi.toggleSharing(_id, user).then(() => {
        // console.log("Toggle sharing OK:", res, this);
        vm.$emit("sharing-modified", user);
        this.$emit("requestRefresh");
      });
    },
    onToggleStatus() {
      console.log("TOGGLE STATUS", this.isOpen);
      const { _id: reconid } = this.reconData;
      const status = this.isOpen ? "closed" : "open";
      ReconsApi.updateById(reconid, { status })
        .then(() => {
          this.$emit("requestRefresh");
        });
    },
    printReceipt() {
      console.log("print");
      window.print();
    },
    dismissPrintReceipt() {
      this.isPrintingReceipt = false;
    },
    // eslint-disable-next-line no-unused-vars
    isValidRfc(str) {
      // eslint-disable-next-line no-unused-vars
      const rfc = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
      // console.log("testing RFC", rfc.test("mora"));
    },
    handlePaste(e) {
      const { clipboardData } = e;
      const { items = [] } = clipboardData;
      let item = null;
      for (let i = 0; i < items.length; ++i) {
        item = items[i];
        // Por ahora solo manejamos 1 paste, pero se podrian manejar multiples
        // console.log("[PASTE] ", item.type);
        if (
          item.type.startsWith("image/")
          || item.type.indexOf("application/pdf") > -1
          || item.type.indexOf("text/xml") > -1
          || item.type.indexOf("application/xml") > -1
        ) {
          console.log("[PASTE] ", item.type, item.getAsFile().name);
          e.preventDefault();
          e.stopPropagation();
          this.onFileDropped(item.getAsFile());
          return;
        }
      }
    },
    onContactSearch(val) {
      console.log("changed", val);
      if (!this.searching) {
        this.searching = true;
        ContactsApi.search({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: it.name,
              item: it
            }));
            this.contactSearchResults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    onTryContactChange(contactid, contactItem) {
      console.log("Request contact swap to", contactid, contactItem);
      this.searchresults = [];
      this.searching = false;
      const { _id: reconid } = this.reconData;
      const vm = this;
      ReconsApi.updateById(reconid, { contact: contactid })
        .then(() => {
          vm.$emit("contact-changed", this.reconData);
          this.fillInitial();
        });
      // QuotationsApi.updateById(this.quotation._id, { contact: contactid })
      //   .then(res => {
      //     this.$set(this.livequotation, res.data);
      //     this.$emit("dbUpdated", res.data);
      //   })
      //   .catch(err => {
      //     // console.warn("Update err", err);
      //   });
    },
    redirectToCfdi(uuid) {
      // console.log("Request redirect page to CFDI:", uuid);
      this.$router.push({
        name: "CFDISingle",
        params: { uuid }
      });
    },
    redirectToBanks(hash) {
      this.$router.push({
        name: "BanksSingle",
        params: { hash }
      });
    },
    setTaxTag(tag) {
      const { _id: reconId = null } = this.reconData ?? {};
      if (!reconId) {
        console.log("ERROR: empty reconId");
        return;
      }
      const tags = ["impuestos"];
      if (tag) {
        tags.push(tag);
      }
      ReconsApi.updateById(reconId, { tags })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$emit("requestRefresh");
        })
        .catch(err => {
          const { message = "Internal server error", response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("TAGS CHANGED FAILED ", errStr);
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo cambiar el tipo",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
        });
    },
    onUpdateTagRecon(recontype, recontag) {
      const { _id: reconId = null } = this.reconData ?? {};
      if (!reconId) {
        console.log("ERROR: empty reconId");
      }
      const query = {
        tags: [recontag],
        type: recontype
      };
      ReconsApi.updateById(reconId, query)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$emit("requestRefresh");
        })
        .catch(err => {
          const { message = "Internal server error", response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("TAGS CHANGED FAILED ", errStr);
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo cambiar el propósito",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          });
        });
    },
    onDateFilterChanged(year, month, day) {
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateApply = true;
      const accountingdate = new Date(year, month - 1, day).toISOString();
      const { _id: reconid } = this.reconData;
      ReconsApi.updateById(reconid, { accountingdate })
        .then(() => {
          this.$emit("requestRefresh");
          this.messageAlert({
            icon: "success",
            title: "Guardado",
            html: `Fecha actualizada a
            <strong>${format(parseISO(accountingdate), "MMMM yyyy", { locale: esmx })}</strong>`,
          });
        })
        .catch(err => {
          console.warn("Update err", err);
        });
    },
    messageAlert(props) {
      const {
        position = "bottom-end",
        toast = true,
        icon = "info",
        title = "sin definir",
        html = "",
        showConfirmButton = false,
        timer = 2500,
        timerProgressBar = true
      } = props ?? {};
      this.$swal({
        position,
        toast,
        icon,
        title,
        html,
        showConfirmButton,
        timer,
        timerProgressBar
      });
    },
  },

  mounted() {
    // const users = [];
    // console.log("R", this.$route);

    // Rellenar los posibles usuarios para compartir
    UsersApi.getUserList().then(res => {
      const { total = 0, rows = [] } = res.data;
      if (total < 1) {
        console.warn(
          "WARNING: No users on userlist or total mismatch, this is a security BUG! STOP NOW!"
        );
        return;
      }
      if (rows.length !== total) {
        console.warn(
          "WARNING: Total user count and rows total mismatch, this is a security BUG!"
        );
      }
      const { _id: myid } = this.user;
      const userlist = rows
        .filter(it => it._id !== myid) // ignorarse a si mismo
        .map(it => {
          const { _id, email, name } = it;
          // console.log("SH", _id, currentUsers);
          return {
            id: _id,
            email,
            name
          };
        });
      this.availableUsersToShare = userlist;
    });

    ReconsApi.vm.$on("image-scaled", res => {
      const { name = "Sin nombre.jpg" } = res.file;
      console.log("[RECONS] IMAGE SCALED:", name, res);
      this.preUploadThumb = res.thumb;
      this.preUploadFilename = name;
      this.preUploadSize = res.thumb.length;
      this.preUploadMimeType = "image/jpeg"; // Siempre que se escala es jpg
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Imagen escalada",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    });
    ReconsApi.vm.$on("file-ready", res => {
      const { file, b64 } = res;
      const { name = "Sin nombre.jpg", size = 0, type } = file;
      // console.log("[RECON] NONIMAGE:", name, type, size, b64.length);
      this.preUploadThumb = "";
      this.preUploadFilename = name;
      this.preUploadSize = size;
      this.preUploadMimeType = type;
      this.preUploadB64 = b64;
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Archivo cargado",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    });
    ReconsApi.vm.$on("file-saved", res => {
      console.log("[RECON] SAVED TO DB AND AWS:", res);
      this.fillInitial();
      this.isSavingFile = false;
    });
    ReconsApi.vm.$on("file-upload-done", () => {
      // console.log("doneeeeee", res);
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Archivo anexado",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    });
    this.$on("sharing-modified", (user) => {
      const { email = "Desconocido", sharing = false } = user;
      const shared = !sharing; // cuando se llama evento ya realizo los cambios
      let msg = `Se ha compartido con ${email}`;
      if (!shared) {
        msg = `Se ha dejado de compartir con ${email}`;
      }
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    });
    this.$on("comment-added", () => {
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Comentario agregado",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    });
    this.$on("file-upload-error", () => {
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "error",
        title: "Error cargando archivo",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      });
    });
    this.$on("file-getsignedurl-error", () => {
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "error",
        title: "Error firmando archivo",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      });
    });
    this.$on("image-scale-error", () => {
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "error",
        title: "Error escalando imagen",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true
      });
    });
    // }, 1500);
  },
  updated() {
    // Se tiene que hacer aqui porque en mounted aun no existe la textarea
    const vm = this;
    const commentArea = vm.$refs["comment-area"];
    if (typeof commentArea === "undefined") {
      return;
    }
    // PELIGRO: Clonarlo elimina los eventlisteners de Vue, no usar.
    // const clone = commentArea.cloneNode(true);
    // commentArea.replaceWith(clone);

    commentArea.removeEventListener("paste", this.handlePaste);
    commentArea.addEventListener("paste", this.handlePaste, false);
  },
  beforeDestroy() {
    // Hay que desconectar las señales para evitar duplicidad
    ReconsApi.vm.$off("image-scaled");
    ReconsApi.vm.$off("file-ready");
    ReconsApi.vm.$off("file-saved");
    ReconsApi.vm.$off("file-upload-done");
  }
};
</script>
