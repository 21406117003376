<style scoped>
.comment-cell {
    width: 99%;
    border-top: unset;
}
</style>

<template>
  <th v-if="isHeader" class="comment-cell" style="display:none;"></th>
  <td v-else class="comment-cell">
    <!-- OJO: Requiere v-html porque se hace linkify y otras cosas -->
    <!--      Ten cuidado de sanitizar el html que agregues o que guardes -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p
      class="comment pn-0"
      style="white-space: pre; font-family: monospace;"
      @mouseover="onMouseOver"
      @click="onCommentClick"
      v-html="comment"
    ></p>
    <small class="comment-date text-right text-muted text-small float-right">
      {{ creationDate }} / {{ rowData.email }}
      <br>
      <strong
        v-if="amount.length>1"
        class="text-right comment-amount"
        :class="{'text-primary':isShown}"
      >
        {{ amount }}
      </strong>
    </small>
    <div v-show="hasCfdiMetadata && isShown" class="smart-meta">
      <div class="border-left border-light pl-3">
        <small class="text-info text-small ">
          <span style="white-space: pre; font-family: monospace; ">{{ cfdi.uuid }} (CFDI V.{{ cfdi.version }})</span> <br>
          <span style="white-space: pre; font-family: monospace; font-weight: bold;">
            Emite : {{ cfdi.em_rfc }}
          </span>
          {{ cfdi.em_nombre }}
          <br>
          <span style="white-space: pre; font-family: monospace; font-weight: bold;">
            Recibe: {{ cfdi.re_rfc }}
          </span>
          {{ cfdi.re_nombre }}
          <br>$ <strong>{{ cfdi.total }}</strong>  <i class="ml-2">({{ cfdi.pagoStr }})</i>
        </small>

        <div class="btn-group ml-2">
          <div class="btn-group">
            <button type="button" data-toggle="dropdown" class="btn btn-xs btn-default dropdown-toggle">
              <!-- <Icon icon="fluent:bot-20-regular" /> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="currentColor" d="M12 5.5a1 1 0 1 0 0 2a1 1 0 0 0 0-2Zm-5 1a1 1 0 1 1 2 0a1 1 0 0 1-2 0Zm3.5-4a.5.5 0 0 0-1 0V3h-3A1.5 1.5 0 0 0 5 4.5v4A1.5 1.5 0 0 0 6.5 10h7A1.5 1.5 0 0 0 15 8.5v-4A1.5 1.5 0 0 0 13.5 3h-3v-.5ZM6.5 4h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5Zm3.75 13.998c2.616-.033 4.195-.595 5.122-1.44c.875-.8 1.089-1.777 1.123-2.556h.005v-.69a1.81 1.81 0 0 0-1.81-1.809H11.5V11.5h-3v.003H5.31c-1 0-1.81.81-1.81 1.81v.689h.005c.034.78.248 1.757 1.123 2.555c.927.846 2.506 1.408 5.122 1.441V18h.5v-.002Zm-4.94-5.495h9.38a.81.81 0 0 1 .81.81v.437c0 .69-.131 1.456-.802 2.069C14.01 16.446 12.66 17 10 17c-2.66 0-4.01-.554-4.698-1.181c-.67-.613-.802-1.38-.802-2.069v-.438a.81.81 0 0 1 .81-.809Z" /></svg>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" style="transform: rotate(360deg);"><g fill="none"><path d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0 7a1 1 0 1 1 0-2a1 1 0 0 1 0 2z" stroke="#626262" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></g></svg> -->
            </button>
            <div class="dropdown-menu">
              <a href="#" class="dropdown-item" @click="autoAttachMetaData">
                Copiar datos a conciliación
              </a>
              <!-- <a href="#" class="dropdown-item">
                Volver a verificar status
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
// import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";
import { parseISO, format } from "date-fns";
import esmx from "date-fns/locale/es";

export default {
  name: "RktCommentField",
  mixins: [VuetableFieldMixin],
  data() {
    return {
      formasDePago: {
        "01": "Efectivo",
        "02": "Cheque",
        "03": "Transferencia ",
        "04": "T. de crédito",
        "05": "Monedero elect.",
        "06": "Dinero elect.",
        "08": "Vales de despensa",
        28: "T. de débito",
        29: "T. de servicio",
        99: "Otros"
      }
    };
  },
  computed: {
    comment() {
      const uuidv4reg = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/ig;
      const uuidv4Utf = /[0-9A-F]{8}‐[0-9A-F]{4}‐4[0-9A-F]{3}‐[89AB][0-9A-F]{3}‐[0-9A-F]{12}/ig; // Guion raro en UTF8
      const moneyreg = /(?=.)(\d{1,3}(,\d{3})*)?(\.\d+)/ig;
      const linkreg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig;

      const reconreg = /c\d{1,4}/ig;
      const quotationreg = /so\d{1,4}/ig;

      return this.rowData.comment
        .replaceAll(reconreg, this.fmtReconLink)
        .replaceAll(quotationreg, this.fmtQuotationLink)
        .replaceAll(uuidv4Utf, this.fmtUUID)
        .replaceAll(moneyreg, this.fmtMoney)
        .replaceAll(linkreg, this.fmtLink);
    },
    amount() {
      const { meta } = this.rowData;
      const { amount = 0, type } = meta;
      const { status = "shown" } = meta;
      return (amount !== 0) ? `$ ${parseFloat(amount).toFixed(2)}` : "";
    },
    creationDate() {
      const { createdAt } = this.rowData;
      const dateOrig = parseISO(createdAt);
      const date = format(dateOrig, "dd MMM, yy", { locale: esmx });
      const time = format(dateOrig, "HH:mm");
      return `${date} ${time}`;
    },
    isShown() {
      const { meta } = this.rowData;
      const { status = "hidden" } = meta;
      return status === "shown";
    },
    hasCfdiMetadata() {
      const { meta = {} } = this.rowData;
      const { cfdi = null, cfdi_status = null } = meta;
      // console.log("CFDI CHECK", cfdi, cfdi_status);
      return cfdi != null;
    },
    cfdi() {
      // Para garantizar que todos los campos estan y no genere errores
      const { meta: { cfdi = {}, cfdi_status = {} } } = this.rowData;

      const {
        emisor = {}, receptor = {},
        forma_pago = "-",
        complemento = [{}],
        fecha = "",
        sub_total = 0, total = 0,
        version = "0"
      } = cfdi;
      const { uuid }  = complemento[0];

      const { rfc: em_rfc = "", nombre: em_nombre = "" } = emisor;
      const { rfc: re_rfc = "", nombre: re_nombre = "" } = receptor;

      const pagoStr = this.formasDePago[forma_pago];
      return {
        em_rfc,
        em_nombre,
        re_rfc,
        re_nombre,
        uuid,
        fecha,
        sub_total,
        total,
        forma_pago,
        pagoStr,
        version
      };
    }
  },
  methods: {
    onMouseOver(ev) {
      const { target = null } = ev;
      const linktype = target?.getAttribute("data-rktype");
      if (linktype) {
        // DEMO: Esta es una mejor manera de detectar hover/out/click dentro de v-html
        console.log("Gotcha",  target, linktype);
      }
    },
    fmtReconLink(str) {
      // console.log("formatting", str);
      return `<a href='#/recons/${str.slice(1)}' style='font-weight: 600;'  data-rktype='recon' >${str}</a>`;
    },
    fmtQuotationLink(str) {
      // console.log("formatting", str);
      return `<a href='#/quotations/${str.slice(2)}' style='font-weight: 600;' data-rktype='quotation'>${str}</a>`;
    },
    fmtUUID(str) {
      return `<a href='#' class='uuid' style='font-weight: 600;' data-rktype='uuid'>${str}</a>`;
    },
    fmtMoney(str) {
      return `<a href='#' class='money' data-rktype='money'>${str}</a>`;
    },
    fmtLink(str) {
      let link = "";
      try {
        // Asumimos que str es una URL
        const domain = (new URL(str)).hostname.replace("www.", "");
        link = `<a target='blank' href='${str}ssh ' class='money'>${domain} 🔗</a>`;
      }
      catch (e) {
        // Falla en casos de nombres de archivos o puntos pegados, lo ignoramos
        link = str;
      }
      return link;
    },
    onCommentClick(e) {
      // console.log(e.target);
    },
    autoAttachMetaData() {
      this.autoCreateAttachContact();
      // this.autoAttachUuid();
    },
    autoCreateAttachContact() {
      // Aqui van estrictamente datos de Contact
      // segun el modelo de Contacts, lo relacionado a uuid,
      // precios y facturas va en el otro evento
      const { meta: { cfdi = {} } } = this.rowData;
      const {
        emisor = {}
      } = cfdi;
      const { nombre, regimen_fiscal:regimen, rfc } = emisor;
      const contactInfo = {
        logid: this.rowData._id,
        nombre,
        rfc,
        regimen,
      };

      // console.log("attach-contact", contactInfo, this.vuetable);
      // Los Field Component pueden usar su propio $emit,
      // pero NO se puede cachar afuera, para evitar ese problema
      // se usa la prop "vuetable" y el evento surge de la tabla
      this.vuetable.$emit("recon-attach-contact", contactInfo);
    },
    autoAttachUuid() {
      const { meta: { cfdi = {} } } = this.rowData;
      const {
        emisor = {},
        receptor = {},
        complemento = {},
        metodo_pago = "",
        forma_pago = "",
        total = ""
      } = cfdi;
      const { nombre:emNombre, rfc: emRfc } = emisor;
      const { nombre:reNombre, rfc: reRfc } = receptor;
      const [comp0 = {}] = complemento;
      const { uuid = "" } = comp0;
      const info = {
        logid: this.rowData._id,
        emisor: emNombre,
        receptor: reNombre,
        cfdi_rfcemite: emRfc,
        cfdi_rfcrecibe: reRfc,
        cfdi_uuid: uuid,
        cfdi_metodopago: metodo_pago,
        cfdi_formapago: forma_pago,
        cfdi_total: total,
      };
      // // Los Field Component deben emitir via la tabla padre
      console.log(info);
      this.vuetable.$emit("recon-attach-cfdiinfo", info);
    }
  },
  mounted() {
    // this.$refs.comment.addEventListener("click", (event) => {
    //   event.preventDefault();
    //   console.log("clicked: ", event.target);
    // });
  }
};
</script>
